.homepage {
  padding: 20% 2.2%;
}

.title h2,
h3 {
  letter-spacing: 0.02em;
  font-weight: 400;
  line-height: 48px;
  padding-top: 30%;
}

.title h2 {
  font-size: 2.7em;
  text-transform: uppercase;
}

.title h3 {
  font-size: 1.8em;
  padding: 4% 0 20%;
  margin-top: -0.8em;
}

.image {
  display: none;
  background: url("../img/IMG_8925.JPG");
  position: relative;
  height: 50vh;
  filter: opacity(0.9);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

/** media queries **/

@media screen and (min-width: 413px) {
  .title h2,
  h3 {
    letter-spacing: 0.02em;
    font-weight: 400;
    line-height: 52px;
    padding-top: 25%;
  }

  .title h2 {
    font-size: 3.2em;
    text-transform: uppercase;
  }

  .title h3 {
    padding: 3% 0 0%;
  }

  .title {
    padding-bottom: 1%;
  }
}

@media screen and (min-width: 500px) {
  .title h2 {
    padding: 10% 0% 2% 0;
  }

  .title h3 {
    padding: 2% 0;
  }
}

@media screen and (min-width: 768px) {
  .image {
    display: unset;
    background: url("../img/IMG_8925.JPG");
    position: relative;
    height: 68vh;
    filter: opacity(0.9);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .homepage {
    padding: 2% 2.6%;
  }

  .title h2 {
    font-size: 3.2em;
    padding-top: 45%;
    text-transform: uppercase;
  }

  .title h3 {
    padding: 3% 0 0%;
  }
}

@media screen and (width: 846px) {
  .image {
    display: unset;
    background: url("../img/IMG_8925.JPG");
    position: relative;
    height: 100vh;
    filter: opacity(0.9);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .title h2 {
    padding: 30% 15% 0% 0;
  }
}

@media screen and (width: 896px) {
  .image {
    display: unset;
    background: url("../img/IMG_8925.JPG");
    position: relative;
    height: 90vh;
    filter: opacity(0.9);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .title h2 {
    padding: 20% 15% 0% 0;
  }
}

@media screen and (min-width: 952px) {
  .image {
    display: unset;
    background: url("../img/IMG_8925.JPG");
    position: relative;
    height: 80vh;
    filter: opacity(0.9);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (height: 1024px) and (width: 768px) {
  .image {
    display: unset;
    background: url("../img/IMG_8925.JPG");
    position: relative;
    height: 50vh;
    filter: opacity(0.9);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 1200px) {
  .homepage {
    padding: 1% 2%;
  }

  .title h2,
  h3 {
    letter-spacing: 0.02em;
    font-weight: 400;
    line-height: 60px;
    padding-top: 30%;
    padding-left: 10%;
  }

  .title h3 {
    padding-left: 10%;
  }
}

@media screen and (height: 1366px) {
  .image {
    display: unset;
    background: url("../img/IMG_8925.JPG");
    position: relative;
    height: 50vh;
    filter: opacity(0.9);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 1440px) {
  .title h2 {
    font-size: 4.6em;
    padding-top: 30%;
    text-transform: uppercase;
  }

  .title h2,
  h3 {
    line-height: 70px;
    padding-top: 30%;
    padding-left: 10%;
  }
  .title h3 {
    padding: 0 10%;
    font-size: 1.8em;
  }
}

@media only screen and (max-height: 375.98px) and (orientation: landscape) {
  .title h2,
  h3 {
    letter-spacing: 0.02em;
    font-weight: 400;
    line-height: 60px;
    padding-top: 7%;
  }

  .title h3 {
    padding: 2% 0 0%;
  }

  .title {
    padding-bottom: 3%;
  }
}

@media screen and (max-width: 1024px) and (min-height: 1366px) {
  .title h3 {
    padding: 2% 0 0%;
  }
  .image {
    display: unset;
    background: url("../img/IMG_8925.JPG");
    position: relative;
    height: 40vh;
    filter: opacity(0.9);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
