.about-section {
  padding: 0 1%;
}

.about-body h2,
p {
  height: 100%;
}

.order-btn {
  font-size: 1.2em;
  border-radius: 0;
  background-color: unset;
  color: black;
  width: 50%;
}

.button-container {
  display: flex;
  padding: 10% 0;
}

.about-left-image {
  background: url("../img/IMG_3681.JPG");
  position: relative;
  height: 50vh;
  filter: opacity(0.9);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-right-image {
  background: url("../img/IMG_9796.JPG");
  position: relative;
  height: 90vh;
  filter: opacity(0.9);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-body h2 {
  font-weight: 400;
  padding: 0 0 3%;
  font-size: 1.4em;
  text-transform: lowercase;
  line-height: 28px;
}

/** media queries **/

@media screen and (min-width: 560px) {
  .about-body h2,
  p {
    padding: 2% 0;
  }

  .about-body h2 {
    font-size: 2.2em;
    line-height: 38px;
  }

  .order-btn {
    width: 40%;
  }

  .button-container {
    display: flex;
    padding: 8% 0;
  }

  .about-left-image {
    background: url("../img/IMG_3681.JPG");
    position: relative;
    height: 60vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 750px) {
  .order-btn {
    width: 30%;
  }

  .about-body p {
    padding-right: 16%;
  }
}

@media screen and (min-width: 768px) {
  .about-body h2 {
    padding: 8% 0 2%;
  }
  .about-body p {
    padding-right: 10%;
  }

  .about-body h2 {
    font-size: 1.8em;
    line-height: 38px;
  }

  .button-container {
    display: flex;
    padding: 8% 0 0;
  }

  .order-btn {
    width: 50%;
  }

  .about-left-image {
    background: url("../img/IMG_3681.JPG");
    position: relative;
    height: 40vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .about-right-image {
    background: url("../img/IMG_9796.JPG");
    position: relative;
    height: 100vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 930px) {
  .about-left-image {
    background: url("../img/IMG_3681.JPG");
    position: relative;
    height: 40vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .order-btn {
    width: 40%;
  }
}

@media screen and (min-width: 992px) {
  .about-body h2,
  p {
    padding-right: 16%;
  }

  .about-left-image {
    position: relative;
    height: 42vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 1180px) {
  .about-left-image {
    position: relative;
    height: 56vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .about-right-image {
    background: url("../img/IMG_9796.JPG");
    position: relative;
    height: 120vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .about-body h2 {
    font-size: 2em;
    line-height: 38px;
  }
  .about-body h2,
  p {
    padding-left: 5%;
  }

  .order-btn {
    width: 30%;
  }

  .button-container {
    padding: 6% 5% 0;
  }
}

@media screen and (min-width: 1200px) {
  .about-body h2,
  p {
    padding-right: 20%;
  }
}

@media screen and (min-width: 1400px) {
  .about-left-image {
    position: relative;
    height: 65vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .about-body h2 {
    font-weight: 400;
    font-size: 2.2em;
    text-transform: lowercase;
    line-height: 50px;
  }

  .about-body p {
    font-size: 1.2em;
  }
  .about-body h2,
  p {
    padding: 2% 0% 2% 2%;
  }

  .button-container {
    padding: 6% 2% 0;
  }
}

@media screen and (min-width: 1440px) {
}

@media screen and (max-width: 768px) and (min-height: 1024px) {
  .about-body h2,
  p {
    padding-right: 12%;
  }

  .about-body h2 {
    font-size: 1.4em;
  }

  .about-right-image {
    background: url("../img/IMG_9796.JPG");
    position: relative;
    height: 50vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 900px) and (min-height: 1440px) {
  .about-body h2,
  p {
    padding-right: 16%;
    background-color: rgb(24, 109, 183);
  }

  .about-left-image {
    position: relative;
    height: 35vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .about-right-image {
    background: url("../img/IMG_9796.JPG");
    position: relative;
    height: 50vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1024px) and (min-height: 1366px) {
  .about-body h2,
  p {
    padding-right: 16%;
  }

  .about-right-image {
    background: url("../img/IMG_9796.JPG");
    position: relative;
    height: 50vh;
    filter: opacity(0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
