/* .smallsquare {
  max-width: 20%;
  height: auto;
  flex: 0 0 20%;
  padding-top: 20%;
  display: grid;
  position: relative;
}

.img-container-grid {
  display: flex;
  flex-wrap: wrap;
}

.img-grid-c {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  object-fit: cover;
  object-position: center;
  padding: 2.5px;
} */
.img-container {
  width: 100%;
}

.ig-container-header {
  text-align: center;
  font-size: 20px;
}
.img-container a:hover {
  opacity: 0.7;
}

.ig-link:hover {
  font-weight: 100;
}

.flex-column {
  width: 350px;
}

.img-grid {
  height: 100%;
}

img {
  margin: 5px;
  object-fit: contain;
  object-position: center;
}

/** media queries **/
@media screen and (min-width: 500px) {
  .flex-column {
    max-width: 400px;
  }
}

@media screen and (min-width: 660px) {
  .flex-column {
    max-width: 300px;
  }

  img {
    margin: 5px;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@media screen and (min-width: 768px) {
  .flex-column {
    max-width: 340px;
  }
}

@media screen and (min-width: 920px) {
  .flex-column {
    max-width: 280px;
  }
}

@media screen and (min-width: 992px) {
  .flex-column {
    max-width: 300px;
  }
}

@media screen and (min-width: 1200px) {
  .flex-column {
    max-width: 280px;
  }
  .ig-hidden-2 img,
  .ig-hidden-1 img {
    display: none;
  }
}

@media screen and (min-width: 1345px) {
  .flex-column {
    max-width: 320px;
  }
}

@media screen and (min-width: 1400px) {
  .flex-column {
    max-width: 330px;
  }
}

@media screen and (min-width: 1500px) {
  .flex-column {
    max-width: 360px;
  }
}

@media screen and (min-width: 1598px) {
  .flex-column {
    max-width: 380px;
  }
}

@media screen and (min-width: 1800px) {
  .ig-hidden-1 img {
    display: unset;
  }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
}

/* .ig-container {
  padding: 0%;
} */
/* 
.instagram-gallery {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
}

.instagram-item {
  flex: calc(100% / 1);
  margin: 5px;
  display: block;
  position: relative;
}

.instagram-image {
  display: block;
  width: 100%;
  height: 99%;
}

.instagram-image:hover {
  opacity: 0.6;
}
 
@media screen and (min-width: 570px) {
  .instagram-item {
    flex: calc(92% / 2);
  }
}

@media screen and (min-width: 768px) {
  .instagram-item {
    flex: calc(90% / 3);
  }
}

@media screen and (min-width: 825px) {
  .instagram-item {
    flex: calc(95% / 3);
  }

  .ig-container {
    padding: 0%;
  }
}
@media screen and (min-width: 992px) {
  .instagram-item {
    flex: calc(93% / 6);
  }
}

@media screen and (min-width: 1030px) {
  .instagram-item {
    flex: calc(93% / 6);
  }

  .ig-container {
    padding: 0%;
  }
}

@media screen and (min-width: 1200px) {
  .instagram-item {
    flex: 0 1 calc(94% / 6);
  }
}

@media screen and (min-width: 1275px) {
  .instagram-item {
    flex: 0 1 calc(95% / 6);
  }
}

@media screen and (min-width: 1400px) {
  .instagram-item {
    flex: 0 1 calc(95.5% / 6);
  }
} */
