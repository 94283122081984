.footer-section {
}

.footer-text {
}

.footer-text h1 {
  font-weight: 400;
  margin: 0;
}

.social-media {
  padding-top: 5%;
}
.social-media p {
  padding: 0;
  margin: 0;
}

.copyright {
  font-size: 0.5rem;
  text-align: center;
  padding: 5% 0% 2%;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

/** media queries **/
@media screen and (min-width: 500px) {
  .copyright {
    font-size: 0.9rem;
    padding: 5% 0% 2%;
  }
}

@media screen and (min-width: 992px) {
  .copyright {
    font-size: 1rem;
  }
}
