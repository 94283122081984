.testimonial-section {
  padding: 5% 2%;
}

.review p {
  padding: 8%;
  text-align: center;
  line-height: 24px;
  font-size: 1em;
  font-weight: 300;
}

.quotation,
.quotation-2 {
  padding: 2% 0%;
}

/** media queries **/
@media screen and (min-width: 768px) {
  .testimonial-section {
    padding: 5% 2%;
  }
  .review {
    padding: 2% 3%;
  }

  .review p {
    line-height: 24px;
    font-size: 0.9em;
  }
}

@media screen and (min-width: 992px) {
  .review {
    padding: 3% 6%;
  }

  .review p {
    line-height: 24px;
    font-size: 1em;
  }
}

@media screen and (min-width: 1200px) {
  .review {
    padding: 2% 4%;
  }
}

@media screen and (min-width: 1400px) {
  .review {
    padding: 2% 4%;
  }

  .review p {
    line-height: 24px;
    font-size: 1.1em;
  }
}
@media screen and (min-width: 1500px) {
  .review {
    padding: 2% 5%;
  }

  .review p {
    padding: 6%;

    line-height: 32px;
    font-size: 1.6em;
  }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
}

.quotation,
.quotation-2 {
  color: #232425;
  font-size: 1.2em;
}

.quotation {
  text-align: left;
}

.quotation-2 {
  text-align: right;
}

@media screen and (max-width: 640px) and (min-height: 360px) {
  .review {
    padding: 1% 10%;
  }

  .review p {
    padding: 2%;
  }
}

@media screen and (max-width: 667px) and (min-height: 375px) {
  .review {
    padding: 1% 10%;
  }

  .review p {
    padding: 2%;
  }
}

@media screen and (max-width: 740px) and (min-height: 360px) {
  .review {
    padding: 0% 14%;
  }

  .review p {
    padding: 0%;
  }
}

@media screen and (max-width: 1024px) and (min-height: 1366px) {
  .review {
    padding: 1% 3%;
  }
}
