.form-title {
  padding: 3% 0 4%;
  text-transform: uppercase;
  text-align: center;
}

.request-form {
  padding: 2% 0;
}

small {
  font-weight: 300;
}

.important-details {
  padding: 2% 0%;
}

.price-details h4 {
  padding-top: 5%;
}

.price-details h4,
.important-details h4 {
  letter-spacing: 1px;
  line-height: 45px;
  text-transform: capitalize;
}

.price-details p,
.important-details p,
h4 {
  padding: 1px;
  margin: 0;
}

.form-body-text {
  padding: 0 4% 0 2%;
}

.form-right {
  padding: 5% 2%;
}

.contact-form {
  padding: 0 2%;
}

.result-sent {
  color: seagreen;
}

.submit-btn {
  font-size: 1.2em;
  border-radius: 0;
  background-color: unset;
  color: black;
  width: 100%;
}
@media screen and (min-width: 570px) {
  .contact-form {
    padding: 0 6%;
  }
}

@media screen and (min-width: 768px) {
  .form-right {
    padding: 0;
  }

  .contact-form {
    padding: 0 2%;
  }
}
