* {
  font-family: "Poppins", sans-serif;
  border-radius: 0;
}

a {
  color: unset;
  text-decoration: none;
}

a:hover {
  font-weight: 500;
  text-decoration: none;
  color: unset;
}
