.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none;
}

.nav-logo {
  font-size: 1em;
}

.navbar-nav {
  justify-content: end;
  width: 100%;
  font-size: 1.2em;
}
